import Link from "next/link";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { richContentOptions } from "components";

import Image from "components/image";

const Heading = (node, children) => {
  return <h6 className="text-sm font-medium text-brand-600">{children}</h6>;
};

const Paragraph = (node, children) => {
  const isEmptyChildren = children?.toString().trim() === "";
  if (isEmptyChildren) return null;
  return (
    <span className="mt-3 text-base text-gray-500 first:flex-1 first:mt-0">
      {children}
    </span>
  );
};

const Bold = (text) => {
  return <strong className="font-medium text-black">{text}</strong>;
};

function I({ fields }) {
  const aspect =
    fields.listingImage.fields.file.details.image.height /
    fields.listingImage.fields.file.details.image.width;
  const aspectClass = aspect === 1 ? "aspect-1 p-1" : "aspect-w-12 aspect-h-7";
  const imgClass =
    aspect === 1
      ? "rounded-lg shadow-lg"
      : "rounded-lg shadow-lg object-cover object-center";
  return (
    <div className="relative text-base w-full">
      <Link href={fields.url ?? ""} className="cursor-pointer">
        <figure>
          <div className={`${aspectClass} rounded-lg shadow-lg`}>
            <Image
              alt=""
              fields={fields.listingImage}
              className={imgClass}
              fill
            />
          </div>
        </figure>
      </Link>
    </div>
  );
}

export default function Card({
  fields,
  options: opts,
  sys,
  includes,
  suppress = false,
}) {
  const options = {
    ...richContentOptions,
    renderMark: {
      ...richContentOptions.renderMark,
      [MARKS.BOLD]: Bold,
    },
    renderNode: {
      ...richContentOptions.renderNode,
      [BLOCKS.HEADING_6]: Heading,
      [BLOCKS.PARAGRAPH]: Paragraph,
    },
  };

  if (!fields.title && !fields.text) {
    return (
      <div className="relative bg-white h-48 p-8 rounded-lg shadow-lg overflow-hidden flex flex-col items-center justify-center">
        {fields.media && (
          <Image
            alt=""
            fields={fields.media}
            width={fields.media.fields.file.details.image.width}
            height={fields.media.fields.file.details.image.height}
          />
        )}
        {fields.listingImage && (
          <Image
            alt=""
            fields={fields.listingImage}
            width={fields.listingImage.fields.file.details.image.width}
            height={fields.listingImage.fields.file.details.image.height}
          />
        )}
      </div>
    );
  }

  let prose = true;
  if (opts && opts.offers) {
    prose = false;
  }
  return (
    <div className="relative flex flex-col rounded-lg shadow-lg overflow-hidden min-h-48">
      {fields.media && (
        <div className="shrink-0 relative overflow-hidden h-96">
          <Image className="object-cover" fields={fields.media} fill alt="" />
        </div>
      )}
      {fields.listingImage && <I fields={fields} />}
      <div className="flex-1 bg-white p-6 flex flex-col">
        <div className="flex-1 flex flex-col">
          {fields.title && (
            <div>
              <h5 className="text-xl font-semibold text-gray-900 mt-0">
                {fields.title}
              </h5>
            </div>
          )}
          {fields.text && !suppress ? (
            <div className="flex flex-col">
              {documentToReactComponents(fields.text, options)}
            </div>
          ) : null}
          {!fields.text && !suppress && (
            <div className="flex flex-col flex-1">
              {documentToReactComponents(fields.listingText, options)}
            </div>
          )}
        </div>
        <div>
          {fields.link && (
            <Link
              href={fields.link}
              className="group relative w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow text-base font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 stretch-link mt-3 no-underline"
            >
              Learn more
            </Link>
          )}
          {fields.url && (
            <Link
              href={fields.url}
              className="group relative w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow text-base font-medium text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 stretch-link mt-3 no-underline"
            >
              Learn more
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
