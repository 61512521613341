import Image, { ImageProps } from "next/image";

type Props = {
  fields: any;
  src?: string;
  className?: string;
} & Omit<ImageProps, 'src'>

export default function CImage ({ fields: { fields }, ...rest}: Props) {
  const alt = fields.title || fields.file.fileName;
  return (
    <Image
      src={`https:${fields.file.url}`}
      alt={alt}
      placeholder='blur'
      blurDataURL="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzAwIiBoZWlnaHQ9IjQ3NSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4="
      {...rest}
      style={{
        maxWidth: "100%",
        // height: "auto"
      }} />
  );
}
